import React from "react"
import qs from "qs"

import { notifyUser as apiNotifyUser } from "../services/tenantsApi"

import PageWrapper from "../components/PageWrapper"
import Form from "../components/Form"

import * as landingPageStyles from "./landing-page.module.css"

import Logo from "../assets/Bunch-Logo/Primary Logo/Colour/Bunch_Primary_Logo_4.png"

const notifyMeOptions = {
  initialPayload: {
    name: "",
    email: "",
    phone: "",
    university: "",
    numberOfBedrooms: "",
    consentToBeContacted: true,
  },
  fields: [
    {
      placeholder: "First name",
      type: "text",
      required: true,
      name: "firstName",
    },
    {
      placeholder: "Last name",
      type: "text",
      required: true,
      name: "lastName",
    },
    {
      placeholder: "Email address",
      type: "email",
      required: true,
      name: "email",
    },
    {
      placeholder: "Phone number",
      type: "tel",
      required: false,
      name: "phone",
    },
    {
      label: "University",
      type: "dropdown",
      required: true,
      name: "university",
      options: [
        {
          label: "Bristol",
          value: "Bristol",
        },
        {
          label: "Manchester",
          value: "Manchester",
        },
        {
          label: "Leeds",
          value: "Leeds",
        },
      ],
    },
    {
      label: "House size",
      type: "range",
      required: false,
      name: "numberOfBedrooms",
      min: 1,
      max: 12,
      defaultValue: 1,
      marks: [
        {
          value: 1,
          label: "1",
        },
        {
          value: 12,
          label: "12",
        },
      ],
    },
    {
      label: "I consent to be contacted by Bunch",
      type: "checkbox",
      name: "consentToBeContacted",
    },
  ],
  submitText: "Submit",
  submitButtonStyle: {
    margin: "0 auto",
  },
}

export default ({ location, pageContext }) => {
  const initalQueryStrings = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const { landingPage } = pageContext

  const [notifyMeError, setNotifyMeError] = React.useState(null)
  const [notifyMeMessage, setNotifyMeMessage] = React.useState(null)
  const [settingUpNotifyMe, setSettingUpNotifyMe] = React.useState(null)

  const notifyMe = async (payload, onSuccess) => {
    console.log(payload)
    try {
      const queryStrings = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      })

      setSettingUpNotifyMe(true)
      setNotifyMeError("")
      setNotifyMeMessage("")
      await apiNotifyUser({
        ...payload,
        city: queryStrings.address,
        numberOfBedrooms: payload.numberOfBedrooms.toString(),
        source: landingPage.source,
        referralCode: initalQueryStrings.fpr,
      })
      setNotifyMeMessage("All Set! We'll be in contact.")
      onSuccess()
      setSettingUpNotifyMe(false)
    } catch (e) {
      console.log(e)
      setNotifyMeError("An error occurred, please try again.")
      setSettingUpNotifyMe(false)
    }
  }

  const renderContent = () => {
    return (
      <div className={landingPageStyles.beatTheRush}>
        <div style={{ textAlign: "left", marginBottom: "20px" }}>
            <img alt="The Bunch logo" style={{ height: "60px" }} src={Logo} />
        </div>
        {landingPage.title && (
          <div
            className="sectionHeader"
            style={{ color: "#FAFAFA", fontSize: 40 }}
          >
            {landingPage.title}
          </div>
        )}
        <div className="sectionSubtitle" style={{ color: "#FAFAFA" }}>
          Bunch's new student property finder platform is now live!
        </div>
        <div className="sectionDescription" style={{ color: "#FAFAFA" }}>
          Register your interest for access to our new bills inclusive student
          properties by filling out the form below.
        </div>
        <img
          className={landingPageStyles.beatTheRushScreenshotMobile}
          src={require("../assets/TB Product Launch (Jan 21) PLN.jpg")}
          alt="Beat The Rush"
        />
        <div className={landingPageStyles.content}>
          <Form
            options={notifyMeOptions}
            initialPayload={notifyMeOptions.initialPayload}
            onSubmit={(payload, onSuccess) => notifyMe(payload, onSuccess)}
            submitting={settingUpNotifyMe}
            apiErrorMessage={notifyMeError}
            apiSuccessMessage={notifyMeMessage}
            hideButtonOnSubmit={true}
          />
          <img
            className={landingPageStyles.beatTheRushScreenshot}
            src={require("../assets/TB Product Launch (Jan 21) PLN.jpg")}
            alt="Beat The Rush"
          />
        </div>
      </div>
    )
  }

  return (
    <PageWrapper
      location={location}
      backgroundColor="#08033D"
      hideHeaderBorder={true}
      hideHeader={true}
    >
      <div id="beattherush" className={landingPageStyles.beatTheRush}>
        {renderContent()}
      </div>
    </PageWrapper>
  )
}
